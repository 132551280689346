import React from "react";
import { Link } from "gatsby";

import Layout from "../components/layout";
import SEO from "../components/seo";

const ContactsPage = () => (
  <Layout>
    <SEO title="Contacts | Constanzia Yurashko" />
    <section className="contacts-section">
      <div className="contacts-section__event">
        <h1>Private room, Paris</h1>
        <hr />
        <p>2 - 6 March 2023 From 10AM</p>
        <p>12 Rur des Gravilliers 75003 Paris</p>
        <p>
          To book an appointment please
          <a
            href="mailto:constanzia.yurashko@gmail.com?subject=Paris Showroom meeting request"
            target="_self"
          >
            &nbsp;CONTACT US
          </a>
        </p>
      </div>
    </section>

    <section className="contacts-section">
      <div className="contacts-section__email">
        <h1>Contacts</h1>
        <p>constanzia.yurashko@gmail.com</p>
      </div>
    </section>
  </Layout>
);

export default ContactsPage;
